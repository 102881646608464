// extracted by mini-css-extract-plugin
export var aArrow1 = "X";
export var aArrow2 = "Y";
export var aButton1 = "N";
export var aButton2 = "O";
export var aButton3 = "P";
export var aButton4 = "Q";
export var aButton5 = "R";
export var aButtonHeaderLogin = "S";
export var aButtonHeaderRegister = "T";
export var aButtonHeaderSpLogin = "W";
export var aButtonLogin = "V";
export var aButtonRegister = "U";
export var aButtons = "ha";
export var aCategory = "L";
export var aCategoryGray = "K";
export var aDate = "M";
export var aDetail = "F";
export var aFullImage = "ga";
export var aLabel = "G";
export var aLink = "I";
export var aTags = "ia";
export var aText = "aa";
export var aTitle = "D";
export var aTitle1 = "fa";
export var animationMarquee = "ma";
export var lSection = "Z";
export var lSection__column2 = "ca";
export var lSection__column2Border = "ba";
export var lSection__content = "da";
export var lSection__contentNoBorder = "ea";
export var lSection__figureBox = "la";
export var lSection__header = "E";
export var lSection__headerNoborder = "C";
export var lSection__iframe = "ka";
export var lSection__iframeBorder = "ja";
export var lSection__inner = "_";
export var lSection__more = "J";
export var lSection__moreNoborder = "H";